@import "../../../assets/scss/app";

.buttonLinkToTop {
  width: vw(40);
  height: vw(40);
  border-radius: 50%;
  border-width: vw(1);
  border-style: solid;
  text-decoration: none;
  @include center();
  background: transparent;
  transition: 0.3s;

  @include tablet {
    width: 40px;
    height: 40px;
    border-width: 1px;
  }

  svg {
    width: vw(36);
    height: vw(36);

    @include tablet {
      width: 36px;
      height: 36px;
    }
  }

  &_light {
    border-color: $color_dark1;

    svg path {
      fill: $color_dark1;
    }

    &:hover {
      background: rgba(0,0,0,0.1);
    }
  }

  &_dark {
    border-color: $color_light2;

    svg path {
      fill: $color_light2;
    }

    &:hover {
      background: rgba(255,255,255,0.1);
    }
  }
}