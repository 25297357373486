.wave {
  animation-name: move;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 20s;
}

@keyframes move {
  0% {
    transform: translateX(-50%);
  }
  25% {
    transform: translateX(calc(-50% - 200px));
  }
  75% {
    transform: translateX(calc(-50% + 200px));
  }
  100% {
    transform: translateX(-50%);
  }
}