@import "../../../assets/scss/app";

.buttonUI {
  @include button;
  @include centerRow;
  position: relative;
  text-decoration: none;
  width: 100%;
  height: vw(60);
  border-radius: vw(16);
  background: linear-gradient(90deg, #44A1F6 0%, #2962F4 100%);
  box-shadow: 0 vw(-2) 0 0 rgba(0, 0, 0, 0.25) inset;
  overflow: hidden;

  &::after {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    transform: translateY(101%);
    transition: 0.3s;
  }

  &:hover {
    @include desktop {
      &::after {
        transform: translateY(0);
      }
    }
  }


  @include tablet() {
    border-radius: 16px;
    box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.25);
    height: 60px;
  }

  p {
    position: relative;
    z-index: 2;
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: vw(20);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    white-space: nowrap;

    @include tablet {
      font-size: 20px;
    }
  }
}
