.selectIcons {

}

.menuItemImg {
  width: 30px;
  height: 30px;
  object-fit: contain;
  //border: 1px solid #000;
  margin-right: 8px;
}

.renderValue {

}
