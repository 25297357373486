@import "../../assets/scss/app";

.portfolioPage {
  position: relative;

  .images {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 1;

    .wave {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: auto;
        bottom: 0;
      }
    }
  }

  .content {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;

    .bigForm {
      padding-top: vw(122);

      @include tablet() {
        padding-top: 122px;
      }
    }
  }
}



