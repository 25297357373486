@import "../../assets/scss/app";

.offer {
  padding-top: vw(82);

  @include tablet() {
    padding-top: 82px;
  }

  @include desktop() {
    padding-top: 192px;
  }

  .buttons {
    @include onlyDesktop() {
      margin-top: 35px;
      .inner {
        margin-left: -10px;
        margin-right: -10px;
        display: flex;
        flex-wrap: wrap;

        @include desktop() {
          margin-left: -21px;
          margin-right: -21px;
        }

        .btn {
          border: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 0 20px 0 0;
          border-radius: 25px;
          background: none;
          transition: 0.3s;
          margin-left: 10px;
          margin-right: 10px;
          margin-top: 10px;

          @include desktop() {
            //padding: 13px 24px 13px 15px;
            padding: 5px 15px 5px 5px;
            border-radius: 38px;
            //margin-left: 21px;
            //margin-right: 21px;
            //margin-top: 30px;
            margin-left: 10px;
            margin-right: 10px;
            margin-top: 15px;
          }

          &_light {
            //box-shadow: inset -3px -3px 5px #FFFFFF, inset 3px 3px 5px #D6D6E1;
            box-shadow: -3px -3px 5px #FFFFFF, 0px 0px 5px #D6D6E1;

            .label {
              color: #292D32;
              opacity: 0.2;
            }
          }

          &_dark {
            //box-shadow: inset -3px -3px 5px #30343A, inset 3px 3px 5px #24262C;
            box-shadow: -3px -3px 5px #30343A, 3px 3px 5px #24262C;

            .label {
              color: #FFF;
              opacity: 0.2;
            }
          }

          &_selected_light {
            //box-shadow: -3px -3px 5px #FFFFFF, 0px 0px 5px #D6D6E1;
            box-shadow: inset -3px -3px 5px #FFFFFF, inset 3px 3px 5px #D6D6E1;

            .label {
              opacity: 1;
            }
          }

          &_selected_dark {
            //box-shadow: -3px -3px 5px #30343A, 3px 3px 5px #24262C;
            box-shadow: inset -3px -3px 5px #30343A, inset 3px 3px 5px #24262C;

            .label {
              opacity: 1;
            }
          }

          .iconWrapper {
            //width: 50px;
            //height: 50px;
            width: 40px;
            height: 40px;
            border-radius: 25px;
            @include center();
            position: relative;

            @include desktop() {
              //width: 50px;
              //height: 50px;
              width: 40px;
              height: 40px;
            }

            .inner {
              //width: 50px;
              //height: 50px;
              width: 40px;
              height: 40px;
              border-radius: 25px;
              opacity: 0.1;
            }

            .icon {
              width: 24px;
              height: 24px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }

          }

          .label {
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 37px;
            margin-left: 10px;
            transition: 0.3s;

            @include desktop() {
              //font-size: 30px;
              //margin-left: 20px;
              font-size: 20px;
              line-height: 37px;
            }
          }

        }


      }
    }
  }

  .select {
    margin-top: vw(30);
    width: 100%!important;

    @include tablet() {
      margin-top: 30px;
    }

    @include desktop() {
      display: none;
    }

    fieldset {
      border: none!important;
      border-radius: vw(15)!important;

      @include tablet() {
        border-radius: 15px!important;
      }
    }

    &_light {
      color: #292D32!important;
      fieldset {
        box-shadow: inset vw(-3) vw(-3) vw(5) #FFFFFF, inset vw(3) vw(3) vw(5) #D6D6E1!important;

        @include tablet() {
          box-shadow: inset -3px -3px 5px #FFFFFF, inset 3px 3px 5px #D6D6E1!important;
        }
      }
    }

    &_dark {
      color: #FFF!important;
      fieldset {
        box-shadow: inset vw(-3) vw(-3) vw(5) #30343A, inset vw(3) vw(3) vw(5) #24262C!important;

        @include tablet() {
          box-shadow: inset -3px -3px 5px #30343A, inset 3px 3px 5px #24262C!important;
        }
      }
    }

  }

  .cards {
    margin-top: vw(30);

    @include tablet() {
      margin-top: 30px;
    }

    @include desktop() {
      flex-direction: row;
      align-items: flex-start;
      margin-top: 30px;
    }

    .cardsItem {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include desktop() {
        flex-direction: row;
        align-items: flex-start;
      }

      &:not(:first-child) {
        margin-top: vw(50);

        @include tablet {
          margin-top: 50px;
        }

        @include desktop {
          margin-top: 64px;
        }
      }

      img {
        object-fit: cover;
        width: vw(512);
        height: vw(400);
        border-radius: vw(30);

        @include tablet() {
          width: 512px;
          height: 417px;
          border-radius: 30px;
        }

        @include desktop() {
          width: 534px;
        }
      }

      .textCard {
        position: relative;
        margin-top: vw(-70);
        background: linear-gradient(85.96deg, rgba(255, 255, 255, 0) -20.51%, rgba(255, 255, 255, 0.05) 26.82%, rgba(255, 255, 255, 0) 65.65%), rgba(196, 196, 196, 0.05);
        box-shadow: vw(15) vw(10) vw(20) rgba(0, 0, 0, 0.1), inset 0 vw(-2) vw(1) rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(30px);
        padding: vw(20)  vw(20) vw(36) vw(44);
        box-sizing: border-box;
        border-radius: vw(36);

        @include tablet() {
          margin-top: -70px;
          box-shadow: 15px 10px 20px rgba(0, 0, 0, 0.1), inset 0px -2px 1px rgba(255, 255, 255, 0.1);
          border-radius: 36px;
          padding: 20px 26px 42px 44px;
        }

        @include desktop() {
          margin-top: 25px;
          margin-left: -60px;
          flex-grow: 1;
        }

        .label {
          font-style: normal;
          font-weight: bold;
          font-size: vw(40);
          line-height: vw(49);
          padding-right: vw(100);

          @include tablet() {
            font-size: 40px;
            line-height: 49px;
            padding-right: 100px;
          }
        }

        .icons {
          box-sizing: border-box;
          padding-right: vw(60);
          display: flex;
          flex-wrap: wrap;

          @include tablet() {
            padding-right: 60px;
          }

          img {
            display: block;
            height: vw(54);
            margin-top: vw(20);
            width: auto;

            @include tablet() {
              height: 54px;
              margin-top: 20px;
            }

            &:not(:last-child) {
              margin-right: vw(13);

              @include tablet() {
                margin-right: 13px;
              }
            }
          }
        }

        .text {
          font-style: normal;
          font-weight: 500;
          font-size: vw(16);
          line-height: vw(20);
          margin-top: vw(15);

          @include tablet() {
            font-size: 16px;
            line-height: 20px;
            margin-top: 15px;
          }

          @include desktop() {
            margin-top: vw(32);

            @include tablet() {
              margin-top: 32px;
            }
          }
        }

        .icon {
          position: absolute;
          top: vw(20);
          right: vw(20);

          @include tablet() {
            top: 20px;
            right: 20px;
          }

          svg {
            width: vw(70);
            height: vw(70);

            @include tablet() {
              width: 70px;
              height: 70px;
            }
          }
        }

      }

      &:nth-child(even) {
        @include desktop {
          flex-direction: row-reverse;
        }

        .textCard {
          @include desktop() {
            margin-left: 0;
            margin-right: -60px;
          }
        }
      }

    }
  }

  /// old style
  .cardWrapper {
    //margin-top: vw(70);
    margin-top: vw(30);
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet() {
      //margin-top: 70px;
      margin-top: 30px;
    }

    @include desktop() {
      flex-direction: row;
      align-items: flex-start;
      //margin-top: 85px;
      margin-top: 30px;
    }

    img {
      width: vw(512);
      height: auto;

      @include tablet() {
        width: 512px;
      }

      @include desktop() {
        width: 534px;
      }
    }

    .card {
      position: relative;
      margin-top: vw(-70);
      background: linear-gradient(85.96deg, rgba(255, 255, 255, 0) -20.51%, rgba(255, 255, 255, 0.05) 26.82%, rgba(255, 255, 255, 0) 65.65%), rgba(196, 196, 196, 0.05);
      box-shadow: vw(15) vw(10) vw(20) rgba(0, 0, 0, 0.1), inset 0 vw(-2) vw(1) rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(30px);
      padding: vw(20) vw(36);
      box-sizing: border-box;
      border-radius: vw(36);

      @include tablet() {
        margin-top: -70px;
        box-shadow: 15px 10px 20px rgba(0, 0, 0, 0.1), inset 0px -2px 1px rgba(255, 255, 255, 0.1);
        border-radius: 36px;
        padding: 20px 36px;
      }

      @include desktop() {
        margin-top: 25px;
        margin-left: -60px;
        flex-grow: 1;
      }

      .label {
        font-style: normal;
        font-weight: bold;
        font-size: vw(40);
        line-height: vw(49);
        padding-right: vw(100);

        @include tablet() {
          font-size: 40px;
          line-height: 49px;
          padding-right: 100px;
        }
      }

      .icons {
        box-sizing: border-box;
        padding-right: vw(60);
        display: flex;
        flex-wrap: wrap;

        @include tablet() {
          padding-right: 60px;
        }

        img {
          display: block;
          height: vw(54);
          margin-top: vw(20);
          width: auto;

          @include tablet() {
            height: 54px;
            margin-top: 20px;
          }

          &:not(:last-child) {
            margin-right: vw(13);

            @include tablet() {
              margin-right: 13px;
            }
          }
        }
      }

      .text {
        font-style: normal;
        font-weight: 500;
        font-size: vw(16);
        line-height: vw(20);
        margin-top: vw(15);

        @include tablet() {
          font-size: 16px;
          line-height: 20px;
          margin-top: 15px;
        }

        @include desktop() {
          margin-top: vw(32);

          @include tablet() {
            margin-top: 32px;
          }
        }
      }

      .icon {
        position: absolute;
        top: vw(20);
        right: vw(20);

        @include tablet() {
          top: 20px;
          right: 20px;
        }

        svg {
          width: vw(70);
          height: vw(70);

          @include tablet() {
            width: 70px;
            height: 70px;
          }
        }
      }

    }
  }


}

.menuItem {
  display: flex;
  align-items: center;

  .iconWrapper {
    width: vw(50);
    height: vw(50);
    border-radius: vw(25);
    @include center();
    position: relative;

    @include tablet() {
      width: 50px;
      height: 50px;
      border-radius: 25px;
    }

    .inner {
      width: vw(50);
      height: vw(50);
      border-radius: vw(25);
      opacity: 0.1;
      @include center();

      @include tablet() {
        width: 50px;
        height: 50px;
        border-radius: 25px;
      }
    }

    .icon {
      //width: vw(24);
      //height: vw(24);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      //@include tablet() {
        width: 24px;
        height: 24px;
      //}

      svg {
        //width: vw(24);
        //height: vw(24);

        //@include tablet() {
          width: 24px;
          height: 24px;
        //}
      }

    }
  }


  .label {
    margin-left: vw(20);
    font-weight: bold;
    font-size: vw(30);
    line-height: vw(37);

    @include tablet() {
      margin-left: 20px;
      font-size: 30px;
      line-height: 37px;
    }
  }
}

.renderValue {

}
