@import "../../../assets/scss/app";

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: vw(23);

  @include tablet() {
    margin-top: 23px;
  }

  .paginationButton {
    @include button;
    @include centerRow;
    transition: 0.2s;
    border-radius: 50%;
    width: vw(50);
    height: vw(50);

    @include tablet() {
      width: 50px;
      height: 50px;
    }

    &:not(:first-child) {
      margin-left: vw(18);

      @include tablet {
        margin-left: 24px;
      }
    }

    p {
      font-weight: bold;
      font-size: vw(24);
      line-height: 120%;
      transition: 0.2s;

      @include tablet {
        font-size: 24px;
      }
    }

    &_light {
      box-shadow: -3px -3px 5px #FFFFFF, 0px 0px 5px #D6D6E1;

      p {
        color: #292D32;
        opacity: 0.2;
      }
    }

    &_dark {
      box-shadow: -3px -3px 5px #30343A, 3px 3px 5px #24262C;

      p {
        color: #FFF;
        opacity: 0.2;
      }
    }

    &_selected_light {
      box-shadow: inset -3px -3px 5px #FFFFFF, inset 3px 3px 5px #D6D6E1;

      p {
        opacity: 1;
      }
    }

    &_selected_dark {
      box-shadow: inset -3px -3px 5px #30343A, inset 3px 3px 5px #24262C;

      p {
        opacity: 1;
      }
    }

    &:hover {
      p {
        opacity: 1;
      }
    }


  }

}
