@mixin tablet {
  @media all and (min-width: 576px) {
    @content;
  }
}

@mixin desktop {
  @media all and (min-width: 1200px) {
    @content;
  }
}

@mixin mobile {
  @media all and (max-width: 1199px) {
    @content;
  }
}

@mixin onlyMobile($displayType: block) {
  display: $displayType;
  @content;

  @include desktop() {
    display: none;
  }
}

@mixin onlyTablet($displayType: block) {
  display: none;

  @include tablet {
    display: $displayType;
    @content;
  }

  @include desktop() {
    display: none;
  }
}

@mixin onlyDesktop($displayType: block) {
  display: none;

  @include desktop {
    display: $displayType;
    @content;
  }
}

@mixin center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin centerColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin centerRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin button {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
}

@mixin absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}











