@import "../../assets/scss/app";

// 445 : 300 = 1.483 : 1
// 350 : 300 = 1,666 : 1
// 445 + 350 + 30 = 845 (475) (16/9=1.777)

.portfolio {
  padding-top: vw(48);

  @include tablet() {
    padding-top: 48px;
  }

  @include desktop() {
    padding-top: vw(68);

    @include tablet() {
      padding-top: 68px;
    }
  }

  .images {
    margin-top: vw(40);

    @include tablet() {
      margin-top: 40px;
    }

    a {
      display: block;
      text-decoration: none;
      border-radius: vw(20);
      overflow: hidden;

      @include tablet {
        border-radius: 20px;
      }

      img {
        display: block;
        object-fit: cover;
        object-position: top;
        transition: 0.4s;
      }

      &:hover img {
        @include tablet {
          transform: scale(1.05);
        }
      }
    }

    // 0...tablet + tablet...859
    .mobile {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media all and (min-width: 859px) {
        display: none;
      }

      a:not(:first-child) {
        margin-top: vw(24);

        @include tablet {
          margin-top: 24px;
        }
      }

      a:nth-child(odd), a:nth-child(odd) img {
        width: vw(445);
        height: vw(300);

        @include tablet {
          width: 445px;
          height: 300px;
        }
      }

      a:nth-child(even), a:nth-child(even) img {
        width: vw(350);
        height: vw(300);

        @include tablet {
          width: 350px;
          height: 300px;
        }
      }

    }

    // 859...desktop
    .middle {
      display: none;

      @media all and (min-width: 859px) {
        display: block;
      }

      @include desktop {
        display: flex;
      }

      .left {

        .row {
          display: flex;
          justify-content: center;

          &:nth-child(1) a:nth-child(2), &:nth-child(2) a:nth-child(2) {
            margin-left: 30px;
          }

          &:nth-child(1) {

            a:nth-child(1), a:nth-child(1) img {
              width: 445px;
              height: 300px;
            }

            a:nth-child(2), a:nth-child(2) img {
              width: 350px;
              height: 300px;
            }
          }

          &:nth-child(2) {
            margin-top: 30px;

            a:nth-child(1), a:nth-child(1) img {
              width: 350px;
              height: 300px;
            }

            a:nth-child(2), a:nth-child(2) img {
              width: 445px;
              height: 300px;
            }

          }
        }

        & > a {
          margin: 30px auto 0;
          width: 845px;

          @include desktop {
            display: none;
          }

          img {
            width: 845px;
            height: auto;
          }
        }
      }

      .right {
        display: none;

        @include desktop {
          display: block;
        }

        margin-left: 30px;
        width: 255px;
        height: 630px;
        border-radius: 20px;

        img {
          display: block;
          width: 255px;
          height: 630px;
        }
      }
    }

  }

  .btn {
    text-decoration: none;
    margin: vw(53) auto 0;
    height: vw(50);
    border-radius: vw(25);
    width: vw(160);
    font-size: vw(16);
    line-height: vw(20);
    display: flex;
    align-items: center;
    text-align: center;
    @include center();

    @include tablet() {
      margin: 53px auto 0;
      height: 50px;
      border-radius: 25px;
      width: 160px;
      font-size: 16px;
      line-height: 20px;
    }

    @include desktop() {
      margin: vw(60) auto 0;

      @include tablet() {
        margin: 60px auto 0;
      }
    }

    &_light {
      color: #292D32;
      box-shadow: -3px -3px 5px #FFFFFF, 0px 0px 5px #D6D6E1;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    &_dark {
      color: #FFFFFF;
      box-shadow: -3px -3px 5px #30343A, 3px 3px 5px #24262C;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }


}
