@import "../../assets/scss/app";

.preloader {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #25303F;
  @include centerRow;

  .circle {
    border: 1px solid #FFF;
    border-radius: 50%;
  }
}
