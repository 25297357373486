@import "../../assets/scss/app";

.header {
  position: relative;
  z-index: 200;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: vw(22);

  @include tablet() {
    padding-top: 22px;
  }

  @include desktop() {
    padding-top: 43px;
  }

  .logoBlock {
    position: relative;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 200;

    & > svg {
      width: vw(240);

      @include tablet() {
        width: 240px;
      }

      @include desktop() {
        width: 350px;
      }
    }

    svg:nth-child(2) {
      position: absolute;
      top: 0;
      left: 0;
    }

  }

  .rightBlock {

    @include desktop {
      margin-top: 11px;
    }

    .topRow {
      display: flex;
      flex-direction: column;

      @include desktop() {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .topRowLeft {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .topRowRight {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: vw(14);

        @include tablet() {
          margin-top: 14px;
        }

        @include desktop() {
          margin-top: 0;
        }

        .langSwitcher {
          @include desktop() {
            margin-left: 150px;
          }
        }

        .socialWrapper {
          position: relative;
          z-index: 200;
          //margin-left: vw(16);

          @include tablet() {
            //margin-left: 16px;
          }

          @include desktop() {
            //margin-left: 150px;
          }

        }

        .tel {
          @include onlyDesktop(flex) {
            margin-left: 81px;
          }
        }
      }
    }

    .BottomRow {
      @include onlyDesktop(flex) {
        margin-top: 30px;
      }
    }

  }
}
