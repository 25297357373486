.customPasswordField {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  .iconWrapper {
    margin-right: 10px;
  }

  .iconButton {
    position: absolute;
    transform: translate(0, -50%);
    top: 50%;
    right: 10px;
  }
}
