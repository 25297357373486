@import "../../assets/scss/app";

.classNameContainer {
  overflow: hidden;
}

.dds {
  padding-top: vw(64);
  display: flex;
  flex-direction: column;
  position: relative;


  @include tablet() {
    padding-top: 64px;
  }

  @include desktop() {
    padding-top: 130px;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }

  //.firstBlock {
  //  @include center();
  //
  //  @include desktop() {
  //    perspective: 900px;
  //    perspective-origin: center;
  //  }
  //
  //  img {
  //    display: block;
  //    transition: 0.6s;
  //    width: vw(300);
  //
  //    @include tablet() {
  //      width: 300px;
  //    }
  //
  //    @include desktop() {
  //      height: 550px;
  //      width: auto;
  //      transform: rotateY(-30deg);
  //    }
  //  }
  //}

  .canvasWrapper {
    width: 100%;
    height: 525px;
    //background: rgba(0, 255, 0, 0.2);
  }

  .img {
    align-self: center;
    height: vw(570);
    width: auto;

    @include tablet {
      height: 570px;
      width: auto;
    }
  }

  .secondBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: vw(-120);

    @include tablet() {
      margin-top: -120px;
    }

    @include desktop() {
      margin-top: 0;
      align-items: flex-start;
      width: 386px;
    }


    .title {
      font-style: normal;
      font-weight: bold;
      font-size: vw(80);
      line-height: vw(98);
      display: flex;
      align-items: center;
      flex-direction: column;

      @include tablet() {
        font-size: 80px;
        line-height: 98px;
      }

      @include desktop() {
        align-items: flex-start;
      }

      p:not(:first-child) {
        margin-top: vw(-10);

        @include tablet() {
          margin-top: -10px;
        }
      }

    }


    .text {
      margin-top: vw(17);
      font-style: normal;
      font-weight: 300;
      font-size: vw(31);
      line-height: vw(38);
      text-align: center;
      width: vw(386);

      @include tablet() {
        margin-top: 17px;
        font-size: 31px;
        line-height: 38px;
        width: 386px;
      }

      @include desktop() {
        text-align: left;
        margin-top: 46px;
      }

      @include desktop() {
        margin-top: 45px;
      }

    }


    .btnWrapper {
      position: relative;

      margin-top: vw(32);

      @include tablet() {
        margin-top: 45px;
      }


      .btn {
        position: relative;
        z-index: 2;
        width: vw(350);

        @include tablet {
          width: 250px;
        }

        &:hover + .buttonAnimation {
          opacity: 1;
        }
      }

      .buttonAnimation {
        opacity: 0;
      }
    }


  }
}



