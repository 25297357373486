.fieldLoadImage {
  display: flex;
  align-items: flex-end;
  position: relative;

  .imgWrapper {
    width: 70px;
    height: 70px;
    //border: 2px solid #777;
    background: #b1b1b1;
    box-sizing: border-box;
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    .img {
      display: block;
      width: 70px;
      height: 70px;
      object-fit: contain;
      border-radius: 10px;
    }
  }

  .dropzone {
    flex-grow: 1;
    margin: 0 0 0 10px;
    display: flex;
    border-width: 1px;
    padding: 0 14px;
    border-radius: 5px;
    border-style: dashed;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.87);
    outline: none;
    transition: .3s;
    height: 73px;
    border-color: rgba(0, 0, 0, 0.5);

    &:hover {
      border-color: #000;
    }

    &_active {
      border-color: #1976d2;
    }

    &_accept {
      border-color: #1976d2;
    }

    &_reject {
      border-color: rgb(211, 47, 47);
    }

    &:focus {
      border-color: #1976d2;

      .legend {
        color: #1976d2;
        transition: .3s;
      }
    }

    .legend {
      font-size: 12px;

      &_error {
        color: red;
      }
    }

    .dropzoneInner {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {

        .leftTop {
          display: flex;
          align-items: center;

          .placeholder {
            user-select: none;
            margin-left: 10px;
          }
        }

        .description {
          margin-top: 2px;
          color: #777c88;
          font-style: italic;
        }
      }



      .error {
        margin-left: 10px;
        color: red;
        font-weight: bold;
      }

    }

  }

  .deleteBtn {
    cursor: pointer;
    height: 70px;
    width: 70px;
    border-radius: 10px;
    background: rgb(162, 33, 33);
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;

    &:hover {
      background: rgb(211, 47, 47);
    }

    &_disable {
      cursor: auto;
      background: grey;

      &:hover {
        background: grey;
      }
    }

  }
}
