@import "../../assets/scss/app";

.footer {
  position: relative;
  z-index: 10;
  padding-top: vw(90);
  padding-bottom: vw(32);
  display: flex;
  flex-direction: column;

  @include tablet() {
    padding-top: 90px;
    padding-bottom: 32px;
  }

  @include desktop() {
    flex-direction: row;
    padding-bottom: 60px;
  }

  .firstBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include desktop() {
      align-items: normal;
      flex-direction: column;
      justify-content: normal;
      flex-grow: 1;
    }

    .logo, logo svg {
      width: vw(190);
      height: vw(69);

      @include tablet() {
        width: 190px;
        height: 69px;
      }
    }

    .theme {
      @include onlyMobile(flex);
    }

    .copy {
      @include onlyDesktop() {
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        margin-top: 30px;
      }
    }

  }

  .secondMobileBlock {
    @include onlyMobile(flex) {
      margin-top: vw(27);
      justify-content: space-between;

      @include tablet() {
        margin-top: 27px;
      }
    }
  }

  .thirdBlock {
    margin-top: vw(38);
    display: flex;
    flex-direction: column;

    @include tablet() {
      margin-top: 38px;
    }

    @include desktop() {
      flex-direction: column;
      justify-content: normal;
      align-items: normal;
      width: 731px;
      margin-top: 0;
    }

    .bottom {
      display: flex;
      align-items: center;
      margin-top: vw(38);

      @include tablet() {
        margin-top: 38px;
      }

      @include desktop() {
        margin-top: 58px;
        justify-content: space-between;
      }

      .tel {
        @include onlyDesktop(flex);
      }

      .theme {
        @include onlyDesktop(flex);
      }

      .langSwitcher {
        @include onlyDesktop(grid) {
          flex-grow: 0;
          flex-shrink: 0;
        }
      };

      .buttonLinkToTopDesktop {
        @include onlyDesktop(flex);
      }

      .adminButtonDesktop {
        @include onlyDesktop(flex);
      }

      .rest {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;

        @include desktop() {
          flex-grow: unset;
        }

        .copy {
          @include onlyMobile(){
            font-weight: normal;
            font-size: vw(14);
            line-height: vw(17);

            @include tablet() {
              font-size: 14px;
              line-height: 17px;
            }
          }
        }

        .social {

        }

        .buttonLinkToTopMobile {
          @include onlyMobile(flex);
        }

        .adminButtonMobile {
          @include onlyMobile(flex);
        }
      }
    }
  }
}
