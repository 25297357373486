@import "../../../assets/scss/app";

.themeSwitcher {
  display: flex;

  .btn {
    cursor: pointer;
    width: vw(34);
    height: vw(34);
    border-radius: 50%;
    @include center();

    @include tablet() {
      width: 34px;
      height: 34px;
    }

    &:first-child {
      margin-right: vw(5);

      @include tablet() {
        margin-right: 5px;
      }

    }

    svg path {
      transition: 0.2s;
    }


    &_left_light {
      box-shadow: -3px -3px 5px #FFFFFF, 0px 0px 5px #D6D6E1;
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    &_left_dark {
      box-shadow: inset -3px -3px 5px #30343A, inset 3px 3px 5px #24262C;
    }

    &_right_light {
      box-shadow: inset -3px -3px 5px #FFFFFF, inset 3px 3px 5px #D6D6E1;
      //transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    &_right_dark {
      box-shadow: -3px -3px 5px #30343A, 3px 3px 5px #24262C;
    }

    svg {
      width: vw(18);
      height: vw(18);

      @include tablet() {
        width: 18px;
        height: 18px;
      }
    }

  }
}