@import "../../assets/scss/app";

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: vw(60);

  @include tablet() {
    padding-top: 60px;
  }

  @include desktop() {
    flex-direction: row-reverse;
    padding-top: vw(100);

    @include tablet() {
      padding-top: 100px;
    }
  }

  .img {
    width: vw(578);
    height: auto;

    @include tablet() {
      width: 578px;
    }

    @include desktop() {
      width: 635px;
      margin-left: 30px;
    }
  }

  .formWrapper {
    margin-top: vw(68);
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet() {
      margin-top: 68px;
    }

    @include desktop() {
      margin-top: 0;
      align-items: flex-start;
    }

    .title {
      font-weight: bold;
      font-size: vw(35);
      line-height: vw(43);
      text-align: center;

      @include tablet() {
        font-size: 35px;
        line-height: 43px;
      }

      @include desktop() {
        text-align: left;
      }
    }

    .subtitle {
      margin-top: vw(35);
      font-weight: 300;
      font-size: vw(24);
      line-height: vw(29);
      text-align: center;

      @include tablet() {
        margin-top: 35px;
        font-size: 24px;
        line-height: 29px;
      }

      @include desktop() {
        text-align: left;
      }
    }

    .formik {
      align-self: stretch;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: vw(50);

      @include tablet() {
        margin-top: 50px;
      }

      @include desktop() {
        align-items: flex-start;
      }

      .fieldWrapper {
        align-self: stretch;
        position: relative;

        &:not(:first-child) {
          margin-top: vw(30);

          @include tablet() {
            margin-top: 30px;
          }
        }

        .error {
          position: absolute;
          left: 20px;
          bottom: 0px;
          transform: translateY(100%);
          color: red;
        }
      }

      .btn, .btn2 {
        width: vw(350);

        @include tablet {
          width: 250px;
        }
      }

      .btn {
        margin-top: vw(54);
        align-self: center;

        @include tablet() {
          margin-top: 54px;
        }

        @include desktop {
          align-self: normal;
        }
      }

      .btn2 {
        align-self: center;
        margin-top: vw(10);

        @include tablet {
          margin-top: 10px;
        }

        @include desktop {
          align-self: normal;
        }
      }
    }
  }
}
