.homePage {
  position: relative;

  .images {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 1;

    .wave {
      position: absolute;
      top: 0;
      left: 50%;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: 2300px;
      }

      &:nth-child(3) {
        top: 4800px;
      }

      &:nth-child(4) {
        top: 6000px;
      }
    }
  }



  .content {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

}