@import "../../../assets/scss/app";

.social {
  display: flex;
  padding: vw(8) vw(14);
  border-radius: vw(17);

  @include tablet() {
    padding: 8px 14px;
    border-radius: 17px;
  }

  &_light {
    box-shadow: inset -3px -3px 5px #FFFFFF, inset 3px 3px 5px #D6D6E1;
  }

  &_dark {
    box-shadow: inset -3px -3px 5px #30343A, inset 3px 3px 5px #24262C;
  }

  @include desktop {
    .icon {
      opacity: 0.2;
      transition: 0.3s;
    }

    &:hover {
      .icon {
        opacity: 1;
      }
    }
  }


  .icon {
    @include center();
    text-decoration: none;
    width: vw(20);
    height: vw(20);

    @include tablet() {
      width: 20px;
      height: 20px;
    }

    &:not(:first-child) {
      margin-left: vw(15);

      @include tablet() {
        margin-left: 15px;
      }
    }

    &_light {
      path {
        fill: #292D32;
      }
    }

    &_dark {
      path {
        fill: #FFF;
      }
    }

    svg {
      width: vw(20);
      height: vw(20);

      @include tablet() {
        width: 20px;
        height: 20px;
      }
    }

  }
}
