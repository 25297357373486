// icon0
.icon0_top {
  animation-name: icon0_anim_top;
  animation-duration: 2s;
  //animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes icon0_anim_top {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(0);
  }
}

.icon0_bottom {
  animation-name: icon0_anim_bottom;
  animation-duration: 2s;
  //animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes icon0_anim_bottom {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(4px);
  }
  100% {
    transform: translateY(0);
  }
}

// icon1
.icon1 {
  animation-name: icon1_anim;
  animation-duration: 6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes icon1_anim {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

// icon2
.icon2 {
  animation-name: icon2;
  animation-duration: 1.5s;
  //animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes icon2 {
  0% {
    //transform: scale(0);
    opacity: 0;
  }
  50% {
    //transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

// icon3
.icon3 {
  animation-name: icon3;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes icon3 {
  0% {
    transform: translate(-3px, -3px);
    //opacity: 0;
  }
  50% {
    transform: translate(3px, 3px);

  }
  100% {
    transform: translate(-3px, -3px);
    //opacity: 0;
  }
}

