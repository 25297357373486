@import "../../assets/scss/app";

.workPage {
  position: relative;

  .threeWrapper {
    @include onlyDesktop();
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0.25;
  }

  .images {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 1;

    @include desktop {
      display: none;
    }

    .wave {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: auto;
        bottom: vw(-100);

        @include tablet() {
          bottom: -100px;
        }
      }

    }
  }

  .content {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;

    .bigForm {
      margin-top: vw(66);

      @include tablet() {
        margin-top: 66px;
      }

      @include desktop() {
        margin-top: 88px;
      }
    }
  }
}
