@import "../../../assets/scss/app";

.portfolioItems {
  overflow: hidden;
  padding-top: vw(115);
  padding-bottom: vw(5);
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;

  @include tablet() {
    padding-top: 115px;
    padding-bottom: 5px;
  }

  @include desktop() {
    padding-top: 80px;
  }

  .menu {
    margin-top: vw(23);

    @include tablet() {
      margin-top: 23px;
    }

    //@include desktop() {
    //  margin-top: 13px;
    //}

    .inner {
      margin-left: vw(-10);
      margin-right: vw(-10);
      display: flex;
      flex-wrap: wrap;

      @include tablet() {
        margin-left: -10px;
        margin-right: -10px;
      }

      //@include desktop() {
      //  margin-left: -20px;
      //  margin-right: -20px;
      //}

      .item {
        cursor: pointer;
        padding: vw(10) vw(20);
        border-radius: vw(37);
        margin: vw(20) vw(10) 0;

        @include tablet() {
          padding: 10px 20px;
          border-radius: 37px;
          margin: 20px 10px 0;
        }

        //@include desktop() {
        //  padding: 20px 90px;
        //  margin: 30px 20px 0;
        //}

        &:hover {
          p {
            opacity: 1;
          }
        }

        p {
          user-select: none;
          font-weight: bold;
          font-size: vw(24);
          line-height: 120%;
          transition: 0.3s;

          @include tablet() {
            font-size: 24px;
          }

          //@include desktop() {
          //  font-size: 30px;
          //}
        }

        &_light {
          //box-shadow: inset -3px -3px 5px #FFFFFF, inset 3px 3px 5px #D6D6E1;
          box-shadow: -3px -3px 5px #FFFFFF, 0px 0px 5px #D6D6E1;

          p {
            color: #292D32;
            opacity: 0.2;
          }
        }

        &_dark {
          //box-shadow: inset -3px -3px 5px #30343A, inset 3px 3px 5px #24262C;
          box-shadow: -3px -3px 5px #30343A, 3px 3px 5px #24262C;

          p {
            color: #FFF;
            opacity: 0.2;
          }
        }

        &_selected_light {
          //box-shadow: -3px -3px 5px #FFFFFF, 0px 0px 5px #D6D6E1;
          box-shadow: inset -3px -3px 5px #FFFFFF, inset 3px 3px 5px #D6D6E1;

          p {
            opacity: 1;
          }
        }

        &_selected_dark {
          //box-shadow: -3px -3px 5px #30343A, 3px 3px 5px #24262C;
          box-shadow: inset -3px -3px 5px #30343A, inset 3px 3px 5px #24262C;

          p {
            opacity: 1;
          }
        }
      }
    }
  }

  .items {
    margin-top: vw(30);

    @include tablet() {
      margin-top: 30px;
    }

    @include desktop() {
      margin-top: 60px; //100px;
    }

  }
}
