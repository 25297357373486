@import "../../../assets/scss/app";

.portfolioItem {
  display: flex;
  flex-direction: column;

  @media all and (min-width: 800px) {
    flex-direction: row;
  }


  &:not(:first-child) {
    margin-top: vw(40);

    @include tablet() {
      margin-top: 40px;
    }

    @include desktop {
      margin-top: -40px;
    }

  }

  .imgWrapper {
    display: block;
    flex-shrink: 0;
    margin-right: vw(18);
    width: 100%;

    @include tablet() {
      margin-right: 0px;
      width: 500px;
      //height: calc((43/50) * 500px);
    }

    @media all and (min-width: 800px) {
      margin-right: 45px;
    }

    @include desktop {
      margin-right: 90px;
      width: 640px;
      height: 600px;
    }

    .monitor {
      width: 100%;
      height: 100%;
      @include onlyDesktop;
    }

    .tablet {
      width: 100%;
      height: 100%;
      @include onlyTablet();
    }

    .mobile {
      width: 100%;
      height: 100%;
      @include tablet {
        display: none;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      border-radius: vw(10);

      @include tablet {
        border-radius: 10px;
      }

      @include desktop {
        display: none;
      }
    }

  }

  .textBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: vw(16);

    @include tablet {
      margin-top: 16px;
    }

    @include desktop {
      margin-top: 0;
    }

    .title {
      text-decoration: underline;
      color: inherit;
      font-weight: 600;
      font-size: vw(20);
      line-height: 160.4%;
      text-align: center;

      @include tablet() {
        font-size: 20px;
        text-align: left;
      }
    }

    .text {
      margin-top: vw(21);
      font-weight: 500;
      font-size: vw(15);
      line-height: vw(18);

      @include tablet() {
        margin-top: 21px;
        font-size: 15px;
        line-height: 18px;
      }
    }

    .countWrapper {
      display: flex;
      align-items: center;
      margin-top: vw(20);

      @include tablet {
        margin-top: 20px;
      }

      svg {
        width: vw(24);
        height: vw(24);

        @include tablet {
          width: 24px;
          height: 24px;
        }
      }

      .count {
        font-weight: 500;
        margin-left: vw(6);
        font-size: vw(15);
        line-height: vw(18);

        @include tablet {
          margin-left: 6px;
          font-size: 15px;
          line-height: 18px;
        }
      }

      &_dark svg path {
        fill: #FFF;
      }

      &_light svg path {
        fill: #000;
      }
    }

  }

  &:nth-child(even) {
    flex-direction: column;

    @include tablet {
      align-items: flex-end;
    }

    @media all and (min-width: 800px) {
      flex-direction: row-reverse;
      align-items: center;
    }

    .imgWrapper {

      @media all and (min-width: 800px) {
        margin-left: 45px;
      }

      @include desktop {
        margin-right: 0;
        margin-left: 90px;
      }
    }

    .textBlock {
      @include tablet {
        align-items: flex-end;
      }

    }
  }


}
