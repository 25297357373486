@import "../../../assets/scss/app";

.burgerMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  box-sizing: border-box;
  transform: translateX(100%);
  transition: 0.3s;

  &_show {
    transform: translateX(0%);
  }

  &_light1 {
    background: $color_light1_darker;
  }

  &_light2 {
    background: $color_light2_darker;
  }

  &_dark1 {
    background: $color_dark1_lighter;
  }

  &_dark2 {
    background: $color_dark2_lighter;
  }

  .inner {
    height: 100%;
    @include center();
    box-sizing: border-box;

    .link {
      text-decoration: none;
      font-weight: normal;
      font-size: vw(36);
      line-height: 125%;
      text-transform: uppercase;

      @include tablet() {
        font-size: 36px;
      }

      &:not(:first-child) {
        margin-top: vw(36);

        @include tablet() {
          margin-top: 36px;
        }
      }

      &_light {
        color: #292D32;
      }

      &_dark {
        color: #FFF;
      }
    }
  }
}