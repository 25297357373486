@import "../../../assets/scss/app";

.burgerButton {
  @include onlyMobile(flex) {
    @include center();
    z-index: 200;
    cursor: pointer;
    width: vw(46);
    height: vw(46);
    background: linear-gradient(90deg, #44A1F6 0%, #2962F4 100%);
    box-shadow: inset 0 -2px 0px rgba(0, 0, 0, 0.25);
    border-radius: vw(23);
    margin-left: vw(24);

    &_modal {
      z-index: 1;
    }

    @include tablet() {
      width: 46px;
      height: 46px;
      border-radius: 23px;
      margin-left: 24px;
    }

    .burger {
      position: relative;
      height: vw(3);
      width: vw(28);

      @include tablet() {
        height: 3px;
        width: 28px;
      }

      .burgerInner {
        height: vw(3);
        width: vw(28);
        border-radius: vw(1.5);
        background: #FFF;
        transition: 0.3s;

        @include tablet() {
          height: 3px;
          width: 28px;
          border-radius: 1.5px;
        }
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        height: vw(3);
        width: vw(28);
        border-radius: vw(1.5);
        background: #FFF;
        left: 0;
        top: vw(-8);
        transition: 0.3s;

        @include tablet() {
          height: 3px;
          width: 28px;
          border-radius: 1.5px;
          top: -8px;
        }
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        height: vw(3);
        width: vw(28);
        border-radius: vw(1.5);
        background: #FFF;
        right: 0;
        bottom: vw(-8);
        transition: 0.3s;

        @include tablet {
          height: 3px;
          width: 28px;
          border-radius: 1.5px;
          bottom: -8px;
        }
      }

      &_burgerMenu {
        .burgerInner {
          transform: scale(0);
        }

        &::before {
          transform: rotate(45deg);
          top: 0;
        }

        &::after {
          transform: rotate(-45deg);
          top: 0;
        }
      }

    }


  }



}
