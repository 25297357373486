@import "../../../assets/scss/app";

.work {
  padding-top: vw(73);

  @include tablet() {
    padding-top: 73px;
  }

  @include desktop() {
    padding-top: 73px;
  }

  .navigateLinks {
    margin-bottom: vw(24);

    @include tablet {
      margin-bottom: 24px;
    }

    .link {
      font-weight: 500;
      font-size: vw(30);
      line-height: vw(37);

      @include tablet() {
        font-size: 30px;
        line-height: 37px;
      }

      &_light {
        color: #5b5b6d;//#292D32;
      }

      &_dark {
        color: #9f9f9f;//#FFF;
      }

    }
  }

  .icons {
    display: flex;
    flex-wrap: wrap;

    .item {
      display: block;
      height: vw(48);
      margin-top: vw(25);
      width: auto;

      @include tablet() {
        height: 48px;
        margin-top: 25px;
      }

      @include desktop() {
        margin-top: 33px;
      }

      &:not(:last-child) {
        margin-right: vw(13);

        @include tablet() {
          margin-right: 13px;
        }
      }
    }
  }

  .textWrapper {
    display: flex;
    flex-direction: column;
    margin-top: vw(50);

    @include tablet() {
      margin-top: 50px;
    }

    @include desktop() {
      margin-top: 36px;
      flex-direction: row;
      align-items: center;
    }

    .text {
      font-weight: 500;
      font-size: vw(16);
      line-height: vw(20);

      @include tablet() {
        font-size: 16px;
        line-height: 20px;
      }

      @include desktop() {
        width: 565px;
      }
    }

    .link {
      font-weight: 500;
      font-size: vw(30);
      line-height: vw(37);
      margin-top: vw(35);

      @include tablet() {
        font-size: 30px;
        line-height: 37px;
        margin-top: 35px;
      }

      @include desktop() {
        margin-top: 0;
        margin-left: 115px;
      }

      &_light {
        color: #292D32;
      }

      &_dark {
        color: #FFF;
      }

    }
  }

  .imgsBlock {
    margin-top: vw(36);

    @include tablet() {
      margin-top: 36px;
    }

    @include desktop() {
      margin-top: 80px;
    }

    .mainWrapper {
      position: relative;

      img {
        display: block;
      }

      .mainMask {
        width: 100%;
        height: auto;
      }

      .main {
        position: absolute;
        object-fit: cover;
        object-position: top;
        top: 5%;
        left: 4%;
        width: 92%;
        height: 64%;

        @include desktop {
          top: 48px;
          left: 50px;
          width: 1010px;
          height: 570px;
        }


      }

    }

    .bottomWrapper {
      display: flex;
      justify-content: space-between;

      .desktopWrapper {
        box-sizing: border-box;
        position: relative;
        width: 77%;
        padding: 5.5% 7.7% 0;

        .desktopMask {
          display: block;
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100%;
          height: auto;
        }

        .desktop {
          position: relative;
          z-index: 2;
          width: 100%;
          border-radius: 5px;
          height: auto;
        }

        &_noMobile {
          width: 100%;
          padding: 7.1% 10.1% 0;
        }
      }

      .mobileWrapper {
        box-sizing: border-box;
        position: relative;
        margin-top: 4.2%;
        width: 22%;
        padding: 3% 1.9% 0;

        .mobileMask {
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: auto;
        }

        .mobile {
          position: relative;
          z-index: 2;
          width: 100%;
          height: auto;
        }

      }
    }

  }


  .bigForm {
    margin-top: vw(66);

    @include tablet() {
      margin-top: 66px;
    }

    @include desktop() {
      margin-top: 88px;
    }

  }
}
