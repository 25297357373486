.app {
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
  overflow: hidden;

  &_light1 {
    background: #FFEAD7;
    color: #292D32;
  }
  &_light2 {
    background: #EFEEF3;
    color: #292D32;
  }
  &_dark1 {
    background: #25303F;
    color: #FFF;
  }
  &_dark2 {
    background: #27292C;
    color: #FFF;
  }

  &_hide {
    height: 100vh;
    overflow: hidden;
  }
}

// light #292D32;  rgba(239, 238, 243, 1)
// dark #25303F;  rgba(37, 48, 63, 1)
