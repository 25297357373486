@import "../../assets/scss/app";

.ourPartners {
  padding-top: vw(90);

  @include tablet() {
    padding-top: 90px;
  }

  .wrapper {
    position: relative;
    overflow: hidden;
    margin-top: vw(63);
    height: vw(50);

    @include tablet() {
      margin-top: 63px;
      height: 50px;
    }

    @include desktop() {
      margin-top: 38px;
    }

    .row {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      height: 100%;

      .slide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        transition: 0.3s;
        width: vw(230);
        height: vw(50);
        padding: 0 vw(25);
        box-sizing: border-box;

        @include tablet() {
          width: 230px;
          height: 50px;
          padding: 0 25px;
        }

        a {
          display: block;
          text-decoration: none;
          width: vw(181);
          //height: vw(50);

          @include tablet() {
            width: 181px;
            //height: 50px;
          }

          svg {
            width: vw(181);

            @include tablet() {
              width: 181px;
            }

            path {
              //fill: #777F89;
              transition: 0.3s;
            }
          }

          img {
            display: block;
            width: vw(181);
            height: auto;

            @include tablet() {
              width: 181px;
            }
          }
        }

        &_selected_light {
          a svg path {
            fill: #000;
          }
        }

        &_selected_dark {
          a svg path {
            fill: #FFF;
          }
        }

      }
    }
  }


  //.swiper {
  //  margin-top: vw(63);
  //
  //  @include tablet() {
  //    margin-top: 63px;
  //  }
  //
  //  @include desktop() {
  //    margin-top: 38px;
  //  }
  //
  //  .slide {
  //    display: flex;
  //    flex-direction: column;
  //    justify-content: center;
  //    transition: 0.3s;
  //    width: vw(230);
  //    height: vw(50);
  //    padding: 0 vw(25);
  //    box-sizing: border-box;
  //
  //    @include tablet() {
  //      width: 230px;
  //      height: 50px;
  //      padding: 0 25px;
  //    }
  //
  //    a {
  //      display: block;
  //      text-decoration: none;
  //      width: vw(181);
  //      //height: vw(50);
  //
  //      @include tablet() {
  //        width: 181px;
  //        //height: 50px;
  //      }
  //
  //      svg {
  //        width: vw(181);
  //
  //        @include tablet() {
  //          width: 181px;
  //        }
  //
  //        path {
  //          //fill: #777F89;
  //          transition: 0.3s;
  //        }
  //      }
  //
  //      img {
  //        display: block;
  //        width: vw(181);
  //        height: auto;
  //
  //        @include tablet() {
  //          width: 181px;
  //        }
  //      }
  //    }
  //
  //    &_selected_light {
  //      a svg path {
  //        fill: #000;
  //      }
  //    }
  //
  //    &_selected_dark {
  //      a svg path {
  //        fill: #FFF;
  //      }
  //    }
  //
  //  }
  //}
  //
  //.pagination {
  //  margin-top: vw(40);
  //
  //  @include tablet() {
  //    margin-top: 40px;
  //  }
  //
  //  @include desktop() {
  //    margin-top: 50px;
  //  }
  //}
}
