@import "../../../assets/scss/app";

.links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  //@include tablet() {
  //  padding-bottom: vw(10);
  //}

  @include desktop() {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  &_light {
    border-bottom-color: #D6D6E1;
  }

  &_dark {
    border-bottom-color: rgba(255, 255, 255, 0.05);
  }

  .link {
    position: relative;
    text-decoration: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: vw(16);
    line-height: vw(20);
    text-transform: uppercase;
    white-space: nowrap;

    @include tablet() {
      font-size: 16px;
      line-height: 20px;
    }

    &::after {
      display: block;
      content: "";
      position: absolute;
      left: 0;
      bottom: vw(-11.5);
      width: 100%;
      height: vw(3);
      border-radius: vw(1.5);
      background: linear-gradient(90deg, #E744F6 0%, #18F5F5 50.08%, #3F5EFB 100%);
      transition: 0.3s;
      transform: scaleX(0);

      @include tablet() {
        bottom: -11.5px;
        height: 3px;
        border-radius: 1.5px;
      }
    }

    &:hover {
      &::after {
        transform: scaleX(1);
      }
    }

    &:not(:first-child) {
      margin-top: vw(25);

      @include tablet() {
        margin-top: 25px;
      }

      @include desktop() {
        margin-top: 0;
        margin-left: 132px;
      }
    }

    &_light {
      color: #292D32;
    }

    &_dark {
      color: #FFF;
    }
  }

}