.itemForm {
  //margin-top: 8px;
  width: 100%;
  box-sizing: border-box;

  .form {
    padding-top: 8px;
    width: 100%;

    .field {
      &:not(:first-child) {
        margin-top: 24px;
      }

    }

    .images {
      width: 100%;
      box-sizing: border-box;
    }

  }
}
