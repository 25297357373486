.adminPage {

  .inner {
    padding: 24px;
    box-sizing: border-box;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .titleWrapper {
        display: flex;
        align-items: center;

        .preloader {
          margin-left: 16px;
          display: flex;
          justify-content: center;
        }
      }
    }

    .list {
      margin-top: 24px;
    }

    .bottom {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;

      .logout {
        text-decoration: none;
      }
    }
  }
}
