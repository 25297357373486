@import "../../../assets/scss/app";

.portfolioListItemWrapper {

  &:not(:first-child) {
    margin-top: 8px;
  }

  .portfolioListItem {
    cursor: move;
    display: flex;

    &_isDragging {
      opacity: 0;
    }

    .img {
      display: block;
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 5px;
    }

    .nameWrapper {
      margin-left: 4px;
      flex-grow: 1;
      height: 50px;
      border-radius: 5px;
      padding: 0 16px;
      background: #454545;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .name {
        color: #FFF;
        font-weight: bold;
        font-size: 18px;

        @include desktop {
          font-size: 24px;
        }
      }

      .viewCountWrapper {
        display: flex;
        align-items: center;

        .viewCount {
          color: #FFF;
          margin-right: 4px;
        }
      }

    }

    .editButton, .deleteButton, .enableButton {
      //@include button;
      margin-left: 4px;
      width: 50px;
      height: 50px;
      border-radius: 5px;


    }

    .enableButton {
      background-color: darkgreen;
      position: relative;

      &:hover {
        background-color: darkgreen;
      }

      &_disable {
        background-color: transparent;
        border: 4px solid grey;

        &:hover {
          background-color: transparent;
        }
      }

      .preloaderWrapper {
        @include  absoluteCenter;
        @include centerRow;
        width: 36px;
        height: 36px;

        .preloader {
          display: block;
          width: 36px;
          height: 36px;
        }
      }
    }

    .editButton {
      position: relative;
      background-color: royalblue;

      &:hover {
        background-color: royalblue;
      }

      .preloaderWrapper {
        @include  absoluteCenter;
        @include centerRow;
        width: 36px;
        height: 36px;

        .preloader {
          display: block;
          width: 36px;
          height: 36px;
        }
      }
    }

    .deleteButton {
      background-color: darkred;

      &:hover {
        background-color: darkred;
      }
    }

  }
}




